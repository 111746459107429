<template>
    <div class="bookshelf-container">
      <div v-if="loading">Loading...</div>
      <div v-else>
        <div v-for="(items, category) in groupedItems" :key="category" class="category">
            <!--吸顶的分类标题-->
            <div class="category-header">{{category}}</div>
          <!-- <h2>{{ category }}</h2> -->
          <div class="grid-container">
            <div v-for="item in items" :key="item.id" class="grid-item" @click="goToBookList(item)">
              <img :src="item.coverUrl" :alt="item.title" class="image" />
              <h3>{{ item.title }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        items: [],
        groupedItems: {},
        loading: true,
      };
    },
    async created() {
      try {
        const response = await axios.get("https://gupengcheng.cn:8091/ai-audio/readers/api/album");
        this.items = response.data.data;
        console.log("this.items = " + this.items);
        this.groupItemsByName();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },
    methods: {
      groupItemsByName() {
        const grouped = {};
        this.items.forEach((item) => {
          if (!grouped[item.groupName]) {
            grouped[item.groupName] = [];
          }
          grouped[item.groupName].push(item);
        });
        this.groupedItems = grouped;
      },

      // 点击书籍跳转到详情页
      goToBookList(album) {
        this.$router.push({
            path: "/booklist", // 目标页面路径
            query: { id: album.albumId, title: album.title } // 传递参数
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .bookshelf-container {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;
    height: 100vh;  /* 让整个页面支持滚动 */
    overflow-y: auto;
    padding-bottom: 50px;
  }
  .category {
    margin-bottom: 20px;
  }
  h2 {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
  }
  .category-header {
  position: sticky;
  top: 0;
  background: white; /* 背景色，防止透明 */
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  z-index: 10;  /* 确保在上方 */
  border-bottom: 2px solid #ddd;
}
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  .grid-item {
    text-align: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background: #f9f9f9;
  }
  .image {
    width: 100%;
    height: auto;
    max-height: 150px;
    object-fit: cover;
    border-radius: 5px;
  }
  </style>
  