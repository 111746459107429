<template>
    <div class="timer">
      <h2>学习时间</h2>
      <div class="time">{{ minutes }}:{{ seconds < 10 ? '0' + seconds : seconds }}</div>
      <button @click="startStopTimer">{{ isRunning ? '停止' : '开始' }}</button>
      <button @click="resetTimer">重置</button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        seconds: 0,
        minutes: 0,
        isRunning: false,
        interval: null
      };
    },
    methods: {
      startStopTimer() {
        if (this.isRunning) {
          clearInterval(this.interval);
        } else {
          this.interval = setInterval(this.incrementTime, 1000);
          this.incrementTimerCount();  // 每次开始计时时，增加计时器次数
        }
        this.isRunning = !this.isRunning;
      },
      incrementTime() {
        this.seconds++;
        if (this.seconds === 60) {
          this.seconds = 0;
          this.minutes++;
        }
      },
      resetTimer() {
        this.seconds = 0;
        this.minutes = 0;
        clearInterval(this.interval);
        this.isRunning = false;
      },
      incrementTimerCount() {
        let count = localStorage.getItem('timerCount') || 0;
        count = parseInt(count) + 1;
        localStorage.setItem('timerCount', count);  // 增加计时器次数并保存
      }
    }
  };
  </script>
  
  <style scoped>
  .timer {
    padding: 20px;
    border: 0px solid #ddd;
    border-radius: 5px;
  }
  .time {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  button {
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
  }
  </style>
  