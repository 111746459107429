import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "../components/LoginPage.vue";
import BookShelf from "../components/BookShelf.vue";
import BookList from "../components/BookList.vue";
import BookDetail from "../components/BookDetail.vue";
import TimeCount from "../components/hides/TimeCount.vue";
import TimerPage from "../components/hides/TimerPage.vue";
import MemoPage from "../components/hides/MemoPage.vue";
import MyPage from "../components/hides/MyPage.vue";
import HomePage from "../components/hides/HomePage.vue";

const routes = [
  { path: "/", component: LoginPage, meta:{title:'溜妈-登录'} },
  { path: "/bookshelf", component: BookShelf, meta:{title:'溜妈-书架'}  },
  { path: "/booklist", component: BookList, meta:{title:'溜妈-列表'}  },
  { path: "/bookdetail", component: BookDetail, meta:{title:'溜妈-书籍'}  },
  { path: "/timecount", component: TimeCount },
  // 测试组件，为了审核通过
  { path: '/timepage', component: TimerPage },
  { path: '/memo', component: MemoPage },
  { path: '/my', component: MyPage },
  { path: '/homepage', component: HomePage, meta:{title:'溜妈-首页'} }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
