<template>
    <div class="book-reader">
      <div v-if="currentPage" class="page">
        <img :src="currentPage.imageUrl" class="page-image" />
        <div class="text-container">
          <p class="chinese-text">{{ currentPage.chineseText }}</p>
          <p class="english-text">{{ currentPage.englishText }}</p>
        </div>
      </div>
      <audio v-if="currentPage" ref="audioPlayer" :src="currentPage.audioUrl" @ended="nextPage" autoplay></audio>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        pages: [],
        currentPageIndex: 0,
      };
    },
    computed: {
      currentPage() {
        return this.pages.length > 0 ? this.pages[this.currentPageIndex] : null;
      },
    },
    async created() {
        try {
            const bookId = this.$route.query.id;
            const bookTitle = this.$route.query.title; 
            console.log("bookId = ", bookId);
            console.log("bookTitle = ", bookTitle);
            const response = await axios.get(`https://gupengcheng.cn:8091/ai-audio/readers/api/page/byBookId/${bookId}`);
            this.pages = response.data.data || [];
            
            if (this.pages.length > 0) {
                // this.$nextTick会等到dom渲染完成后才调用
                this.$nextTick(() => {
                    this.playAudio();
                });
            }
        } catch (error) {
            console.error("获取书籍详情失败:", error);
        }
    },
    methods: {
      playAudio() {
        if (!this.currentPage || !this.currentPage.audioUrl) {
            console.error("音频数据不存在");
            return;
        }
        if (this.$refs.audioPlayer) {
            this.$refs.audioPlayer.play();
        } else {
            console.error("audioPlayer组件未找到");
        }
      },
      nextPage() {
        if (this.currentPageIndex < this.pages.length - 1) {
          this.currentPageIndex++;
          this.$nextTick(() => this.playAudio());
        }
      },
    },
    // 添加 beforeUnmount，销毁音乐播放器，避免内存泄露
    beforeUnmount() {
        if (this.$refs.audioPlayer) {
        this.$refs.audioPlayer.pause(); // 停止播放
        this.$refs.audioPlayer.currentTime = 0; // 重置进度
        }
    },
  };
  </script>
  
  <style scoped>
  .book-reader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
    position: relative;
  }
  
  .page {
    position: relative;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .page-image {
    width: 100%;
    height: auto;
    max-height: 80%;
    object-fit: contain;
  }
  
  .text-container {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 10px;
  }
  
  .chinese-text {
    color: white;
    font-size: 24px;
  }
  
  .english-text {
    color: lightgray;
    font-size: 20px;
  }
  </style>
  