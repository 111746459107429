<template>
    <div class="memo-page">
      <h2 class="page-title">你的笔记</h2>
  
      <textarea
        v-model="memoText"
        placeholder="输入你的笔记..."
        class="memo-textarea"
      ></textarea>
  
      <button @click="saveMemo" class="btn save-btn">保存</button>
  
      <div v-if="savedMemo" class="saved-memo">
        <h3>Saved Memo:</h3>
        <p>{{ savedMemo }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        memoText: '',
        savedMemo: localStorage.getItem('memo') || ''
      };
    },
    methods: {
      saveMemo() {
        if (this.memoText.trim()) {
          this.savedMemo = this.memoText;
          localStorage.setItem('memo', this.memoText);
          this.memoText = '';
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .memo-page {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 20px auto;
  }
  
  .page-title {
    font-size: 2rem;
    font-weight: 700;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .memo-textarea {
    width: 100%;
    height: 150px;
    padding: 15px;
    font-size: 1rem;
    border-radius: 8px;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
  }
  
  .memo-textarea:focus {
    border-color: #4CAF50;
    outline: none;
  }
  
  .save-btn {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
    width: 100%;
  }
  
  .save-btn:hover {
    background-color: #45a049;
  }
  
  .saved-memo {
    margin-top: 20px;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .saved-memo h3 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .saved-memo p {
    font-size: 1rem;
    color: #666;
  }
  </style>
  