<template>
    <div class="my-page">
      <h2 class="page-title">我的</h2>
  
      <div class="section">
        <h3 class="section-title">你的笔记:</h3>
        <div v-if="savedMemo" class="memo-content">
          <p>{{ savedMemo }}</p>
        </div>
        <div v-else class="no-memo">
          <p>No memo saved yet.</p>
        </div>
      </div>
  
      <div class="section">
        <h3 class="section-title">倒计时总数:</h3>
        <div class="timer-count-display">
          <p>{{ timerCount }} 条</p>
        </div>
      </div>
  
      <div class="actions">
        <button class="btn reset-btn" @click="resetTimerCount">重置</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        savedMemo: localStorage.getItem('memo') || '10条',
        timerCount: localStorage.getItem('timerCount') || 3
      };
    },
    methods: {
      resetTimerCount() {
        this.timerCount = 0;
        localStorage.setItem('timerCount', this.timerCount);
      }
    }
  };
  </script>
  
  <style scoped>
  .my-page {
    background: #f9f9f9;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 0 auto;
  }
  
  .page-title {
    font-size: 2rem;
    font-weight: 700;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .section {
    margin-bottom: 30px;
  }
  
  .section-title {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .memo-content {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    font-size: 1rem;
    color: #666;
  }
  
  .no-memo {
    color: #888;
    font-style: italic;
  }
  
  .timer-count-display {
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    font-size: 1.2rem;
    color: #333;
  }
  
  .actions {
    display: flex;
    justify-content: center;
  }
  
  .btn {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
  }
  
  .btn:hover {
    background-color: #45a049;
  }
  
  .reset-btn {
    background-color: #FF5722;
  }
  
  .reset-btn:hover {
    background-color: #e64a19;
  }
  </style>
  