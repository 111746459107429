<template>
    <div class="book-list-container">
      <div v-if="loading">Loading...</div>
      <div v-else>
        <div class="title-header">{{albumTitle}}</div>
        <div class="grid-container">
            <div v-for="item in items" :key="item.id" class="grid-item" @click="goToBookDetail(item)">
              <img :src="item.coverUrl" :alt="item.title" class="image" />
              <h3>{{ item.title }}</h3>
            </div>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        items: [],
        loading: true,
        albumTitle:''
      };
    },
    async created() {
      try {
        const albumId = this.$route.query.id;
        const albumTitle = this.$route.query.title; 
        this.albumTitle = albumTitle;
        console.log("当前书架 albumId:", albumId);
        console.log("当前书架 albumTitle:", albumTitle);
        const response = await axios.get(`https://gupengcheng.cn:8091/ai-audio/readers/api/book/byAlbumId/${albumId}`);
        this.items = response.data.data;
        console.log("this.items = " + this.items);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },
    methods: {
      // 点击书籍跳转到详情页
      goToBookDetail(book) {
        // console.log("book = ", book);
        // console.log("bookId = ", book.bookId)
        this.$router.push({
            path: "/bookdetail", // 目标页面路径
            query: { id: book.bookId, title: book.title } // 传递参数
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .book-list-container {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;
    height: 100vh;  /* 让整个页面支持滚动 */
    overflow-y: auto;
    padding-bottom: 50px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  .title-header {
  position: sticky;
  top: 0;
  background: white; /* 背景色，防止透明 */
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  z-index: 10;  /* 确保在上方 */
  border-bottom: 2px solid #ddd;
}
  .grid-item {
    text-align: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background: #f9f9f9;
  }
  .image {
    width: 100%;
    height: auto;
    max-height: 150px;
    object-fit: cover;
    border-radius: 5px;
  }
  </style>
  