<template>
    <div>
      <div class="word-container">
        <transition name="fade">
          <p v-if="currentWord" class="word">{{ currentWord }}</p>
        </transition>
      </div>
      <div>
        <label for="mode">计时模式：</label>
        <select v-model="mode" id="mode">
          <option value="countup">正向计时</option>
          <option value="countdown">倒计时</option>
        </select>
      </div>
      <div v-if="mode === 'countdown'">
        <label for="targetTime">目标时间（秒）：</label>
        <input type="number" v-model="targetTime" id="targetTime" min="0">
      </div>
      <h1>{{ formattedTime }}</h1>
      <button @click="start">开始</button>
      <button @click="pause">暂停</button>
      <button @click="reset">重置</button>
    </div>
  </template>
  
  <script setup>
  import { ref, computed, onUnmounted, setInterval } from 'vue';
  
  // 定义计时模式
  const mode = ref('countup');
  // 目标时间，用于倒计时模式
  const targetTime = ref(0);
  // 存储时间和定时器 ID 的响应式变量
  const time = ref(0);
  const intervalId = ref(null);
  // 英语单词数组
  const words = ref([
    'apple', 'banana', 'cherry', 'date', 'elderberry',
    'fig', 'grape', 'honeydew', 'kiwi', 'lemon'
  ]);
  // 当前显示的单词
  const currentWord = ref('');
  // 单词切换定时器 ID
  const wordIntervalId = ref(null);
  
  // 计算属性，将时间格式化为 mm:ss
  const formattedTime = computed(() => {
    let currentTime = mode.value === 'countdown' ? Math.max(targetTime.value - time.value, 0) : time.value;
    const minutes = Math.floor(currentTime / 60).toString().padStart(2, '0');
    const seconds = (currentTime % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  });
  
  // 开始计时的函数
  const start = () => {
    if (!intervalId.value) {
      intervalId.value = setInterval(() => {
        if (mode.value === 'countdown' && time.value >= targetTime.value) {
          pause();
          // 可以在这里添加倒计时结束的提醒逻辑
          alert('倒计时结束！');
        } else {
          time.value++;
        }
      }, 1000);
      // 开始单词切换
      wordIntervalId.value = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * words.value.length);
        currentWord.value = words.value[randomIndex];
      }, 2000);
    }
  };
  
  // 暂停计时的函数
  const pause = () => {
    clearInterval(intervalId.value);
    intervalId.value = null;
    clearInterval(wordIntervalId.value);
    wordIntervalId.value = null;
  };
  
  // 重置计时的函数
  const reset = () => {
    pause();
    time.value = 0;
    currentWord.value = '';
  };
  
  // 组件卸载时清除定时器
  onUnmounted(() => {
    clearInterval(intervalId.value);
    clearInterval(wordIntervalId.value);
  });
  </script>
  
  <style scoped>
  button {
    margin: 5px;
  }
  
  .word-container {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .word {
    font-size: 24px;
    opacity: 0;
  }
  
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  
  .fade-enter-to,
  .fade-leave {
    opacity: 1;
  }
  </style>    