// main.js
import { createApp } from 'vue';
import App from './App.vue';
// import { createMetaManager } from 'vue-meta';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import router from "./router/index"; // 引入路由

const app = createApp(App);
app.use(ElementPlus);
app.use(router);
// app.use(createMetaManager(false, {
//     meta: { tag: 'meta', nameless: true }
// }))
app.mount('#app');