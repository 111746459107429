<template>
  <div class="login-container">
    <el-card class="login-card">
      <h1 class="title">溜妈</h1>
      <el-form :model="form" class="login-form">
        <el-form-item>
          <el-input v-model="form.phone" placeholder="请输入手机号码" prefix-icon="el-icon-phone" />
        </el-form-item>
        <el-form-item class="code-container">
          <el-input v-model="form.code" placeholder="请输入验证码" prefix-icon="el-icon-key">
            <template #append>
              <el-button :disabled="countDown" @click="getCode">
                {{ countDownText }}
              </el-button>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <el-button class="login-btn" type="primary" @click="handleLogin">
        立即登录
      </el-button>
    </el-card>
    <!-- 新增备案信息 -->
    <div class="record-info">
      蜀ICP备2024094846号
      Copyright © 2025 溜妈 All Rights Reserved
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from "vue-router";
import axios from "axios";
import { ElMessage } from 'element-plus';
import Fingerprint2 from 'fingerprintjs2';

const form = ref({
  phone: '',
  code: ''
});
const deviceInfo = ref('');

const countDown = ref(false);
const countDownText = ref('获取验证码');
const timer = ref(null);
const router = useRouter();

const handleCodeSendSuccess = () => {
  countDown.value = true;
  let seconds = 60;
  timer.value = setInterval(() => {
    seconds--;
    countDownText.value = `${seconds}s后重新获取`;
    if (seconds <= 0) {
      clearInterval(timer.value);
      countDown.value = false;
      countDownText.value = '获取验证码';
    }
  }, 1000);
}

const getCode = async () => {
  if (!form.value.phone.trim()) {
    console.log("没有输入手机号码");
    ElMessage({
      message: '请输入手机号码',
      type: 'error',
      duration: 800 // 持续时间，单位为毫秒
    });
    return;
  }
  try {
    getDeviceInfo();
    getDeviceFingerprint();
    const sendCodeRequest = {
      phoneNumber: form.value.phone.trim(),
      deviceId: deviceInfo.value
    };
    console.log("deviceInfo.value = ", deviceInfo.value + " , deviceFingerprint.value = " + deviceFingerprint.value);
    const response = await axios.post('https://gupengcheng.cn:8091/ai-audio/readers/api/sms/sendCode', sendCodeRequest);
    console.log("getCode response = ", response);
    if (response != null && response.data!= null && response.data.data != null && response.data.data.success) {
      ElMessage({
        message: '验证码发送成功',
        type: 'success',
        duration: 800 // 持续时间，单位为毫秒
      });
      handleCodeSendSuccess();
    } else {
      if (response != null && response.data != null && response.data.message != null) {
        const responseMsg = response.data.message;
        console.log("验证码出错=", responseMsg);
        ElMessage({
          message: responseMsg,
          type: 'error',
          duration: 800 // 持续时间，单位为毫秒
        });
      }
    }
  } catch (error) {
    console.log("验证码出错=", error);
    ElMessage({
      message: '验证码发送异常',
      type: 'error',
      duration: 800 // 持续时间，单位为毫秒
    });
  }
};
const getDeviceInfo = () => {
  const userAgent = navigator.userAgent;
  const platform = navigator.platform;
  const deviceInfoStr = `User Agent: ${userAgent}, Platform: ${platform}`;
  console.log("deviceInfoStr = ", deviceInfoStr);
  deviceInfo.value = deviceInfoStr;
};

const handleLoginSuccess = (data) => {
  console.log("handleLoginSuccess data = ", data);
  if (data && data.token) {
    console.log("token = ", data.token);
    localStorage.setItem('token', data.token); // 存储登录状态
    router.push("/homepage"); // 跳转到列表页
  } else {
    console.log("handleLoginSuccess not valid response = ");
  }
};

const handleLogin = async () => {
  // 这里添加登录逻辑

  if (!form.value.phone.trim()) {
    console.log("没有输入手机号码");
    ElMessage({
      message: '请输入手机号码',
      type: 'error',
      duration: 800 // 持续时间，单位为毫秒
    });
    return;
  }
  if (!form.value.code.trim()) {
    console.log("没有输入验证码");
    ElMessage({
      message: '请输入验证码',
      type: 'error',
      duration: 800 // 持续时间，单位为毫秒
    });
    return;
  }
  try {
    getDeviceInfo();
    getDeviceFingerprint();
    const loginRequest = {
      phoneNumber: form.value.phone.trim(),
      deviceId: deviceInfo.value,
      fromDevice:1,
      code: form.value.code.trim()
    };
    console.log("deviceInfo.value = ", deviceInfo.value + " , deviceFingerprint.value = " + deviceFingerprint.value);
    const response = await axios.post('https://gupengcheng.cn:8091/ai-audio/readers/api/auth/login', loginRequest);
    console.log(response);
    console.log("response param ", "response != null is " + (response != null ) + "response.data!= null  is " + (response.data!= null) + " | response.data.token !=null is " + (response.data.token !=null));
    if (response != null && response.data!= null && response.data.data!= null && response.data.data.token !=null) {
      console.log("response before ElMessage");
      ElMessage({
        message: '登录成功',
        type: 'success',
        duration: 800 // 持续时间，单位为毫秒
      });
      console.log("response before handleLoginSuccess ");
      handleLoginSuccess(response.data.data);
    } else {
      if (response != null && response.data != null && response.data.message != null) {
        const responseMsg = response.data.message;
        console.log("登录出错=", responseMsg);
        ElMessage({
          message: responseMsg,
          type: 'error',
          duration: 800 // 持续时间，单位为毫秒
        });
      }
    }
  } catch (error) {
    console.log("登录出错=", error);
    ElMessage({
      message: '登录异常',
      type: 'error',
      duration: 800 // 持续时间，单位为毫秒
    });
  }
};

  // console.log('登录参数：', form.value);
  // if (form.value.phone === "admin" && form.value.code === "8888") {
  //   console.log('跳转bookshelf');
  //   localStorage.setItem('isLoggedIn', true); // 存储登录状态
  //   router.push("/homepage"); // 跳转到列表页
  // } else {
  //   console.log("手机号或验证码错误，请重新输入");
  // }
  

const deviceFingerprint = ref('');

const getDeviceFingerprint = () => {
  if (window.requestIdleCallback) {
    requestIdleCallback(() => {
      Fingerprint2.get((components) => {
        const values = components.map((component) => component.value);
        const fp = Fingerprint2.x64hash128(values.join(''), 31);
        deviceFingerprint.value = fp;
      });
    });
  } else {
    setTimeout(() => {
      Fingerprint2.get((components) => {
        const values = components.map((component) => component.value);
        const fp = Fingerprint2.x64hash128(values.join(''), 31);
        deviceFingerprint.value = fp;
      });
    }, 500);
  }
};

</script>

<style scoped lang="scss">
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #42a5f5, #1e88e5);
}

.login-card {
  padding: 40px 60px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  background: white;
}

.title {
  font-size: 32px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
}

.login-form {
  width: 360px;
}

.el-form-item {
  margin-bottom: 24px;
}

.code-container {
  position: relative;
}

.login-btn {
  width: 100%;
  height: 50px;
  font-size: 18px;
  border-radius: 12px;
  margin-top: 24px;
}

.el-input__inner {
  height: 50px;
  font-size: 16px;
}

.el-input__prefix {
  font-size: 20px;
  color: #666;
}

.el-button--primary {
  background-color: #42a5f5;
  border-color: #42a5f5;

  &:hover {
    background-color: #1e88e5;
    border-color: #1e88e5;
  }
}

.record-info {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  color: #000;
  font-size: 14px;
}
</style>