<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <metainfo></metainfo>
  <!-- <LoginPage /> -->
  <!--必须配置router-view才能跳转route-->
  <router-view /> 
</template>

<script>
export default {
  name: 'App'
};
// // import HelloWorld from './components/HelloWorld.vue'
// // import HouseInfoList from './HouseInfoList.vue'
// import LoginPage from './components/LoginPage.vue';
// // import { useMeta } from 'vue-meta';
// // import customIcon from '@/assets/logo.png';
// export default {
//   name: 'App',
//   components: {
//     // HelloWorld
//     LoginPage
//   },
//   // mounted() {
//   //    useMeta({
//   //      title: '溜妈',
//   //      htmlAttrs: { 
//   //        lang: 'en', 
//   //        amp: true,
//   //      },
//   //      meta: [
//   //        {
//   //         hid: 'icon',
//   //         rel: 'icon',
//   //         href: customIcon
//   //        }
//   //      ]
//   //    })
//   //  }
// }
</script>

<style lang="scss">
#app {
  min-height: 100vh; /* 让它至少占满整个视口 */
  overflow-y: auto;
  overflow: auto;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
}
</style>