<template>
    <div class="home-page">
        <header>
            <h1>溜妈工具</h1>
            <nav>
                <button v-for="tab in tabs" :key="tab.name" @click="setTab(tab.name)"
                    :class="{ active: currentTab === tab.name }">
                    {{ tab.label }}
                </button>
            </nav>
        </header>
        <main>
            <keep-alive>
                <component :is="getComponent(currentTab)" class="content-container"></component>
            </keep-alive>
        </main>
        <!-- 备案信息 -->
        <footer class="record-info">
            蜀ICP备2024094846号 &nbsp; | &nbsp; Copyright © 2025 溜妈 All Rights Reserved
        </footer>
    </div>
</template>

<script>
import TimerPage from "./TimerPage.vue";
import MemoPage from "./MemoPage.vue";
import MyPage from "./MyPage.vue";

export default {
    name: 'HomePage',
    components: {
        TimerPage,
        MemoPage,
        MyPage
    },
    data() {
        return {
            currentTab: "time", // 默认显示“倒计时”页面
            tabs: [
                { name: "time", label: "倒计时", component: TimerPage },
                { name: "memo", label: "备忘录", component: MemoPage },
                { name: "my", label: "我的", component: MyPage },
            ],
        };
    },
    mounted() {
        // 组件挂载后执行的代码
        const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
        console.log(`HomeView Component has been mounted, isLoggedIn${isLoggedIn}`);
    },
    methods: {
        setTab(tabName) {
            this.currentTab = tabName;
        },
        getComponent(tabName) {
            return this.tabs.find(tab => tab.name === tabName)?.component || TimerPage;
        }
    }
};
</script>

<style scoped>
/* 页面整体布局 */
.home-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* 让页面占满整个屏幕 */
    text-align: center;
}

/* 头部标题 */
header {
    background-color: #4CAF50;
    padding: 0px;
    color: white;
    font-size: 22px;
    font-weight: bold;
}

/* 导航栏 */
nav {
    display: flex;
    justify-content: center;
    gap: 60px;
    padding: 15px;
    background: #f0f0f0;
}

/* Tab 按钮样式 */
nav button {
    flex: 1;
    max-width: 120px;
    /* 控制按钮最大宽度 */
    padding: 15px 25px;
    border: none;
    border-radius: 25px;
    background: #ddd;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
}

/* 选中的 Tab 高亮 */
nav button.active {
    background: #4CAF50;
    color: white;
    transform: scale(1.1);
}

/* Tab 按钮悬停效果 */
nav button:hover {
    background: #bbb;
}

/* 主内容区域，占满剩余高度 */
main {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: #f9f9f9;
    border-top: 2px solid #ddd;
    overflow-y: auto;
    /* 避免内容溢出 */
}

/* 确保子组件填充 main */
.content-container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

/* 备案信息，固定在页面底部 */
.record-info {
  background: #222;
  color: white;
  padding: 10px;
  font-size: 14px;
  text-align: center;
}
</style>